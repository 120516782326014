<template>
  <v-footer
    v-if="$vuetify.breakpoint.smAndDown"
    id="home-footer"
    color="black"
    dark
    fixed
    min-height="35"
    bottom
  >
    <v-row class="px-0">
      <v-col sm="8" class="px-0">
        <div class="d-flex">
          <template v-for="s in social">
            <template v-if="s === 'Facebook'">
              <a
                :key="s"
                class="white--text"
                href="https://www.facebook.com/FirstVault-108596295226047/"
                target="_blank"
              >
                <v-icon>mdi-facebook</v-icon> &nbsp;</a
              >
            </template>
            <template v-else-if="s === 'LinkedIn'">
              <a
                :key="s"
                class="white--text"
                href="https://in.linkedin.com/company/hedge-vision-systems-private-limited"
                target="_blank"
                ><v-icon>mdi-linkedin</v-icon> &nbsp;</a
              >
            </template>
          </template>
          <div class="pointerCursor smallFont" @click="showPrivacyPolicy">
            <span>&nbsp;Privacy Policy&nbsp;</span>
          </div>
          <div class="pointerCursor smallFont" @click="showTermsOfUse">
            <span>&nbsp;Terms of Use&nbsp;</span>
          </div>
        </div>
      </v-col>

      <v-col class="smallFont px-0 text-md-right" sm="4">
        Copyright&copy; 2020-{{ getYear }} Hedge Vision Systems Pvt. Ltd.
      </v-col>
    </v-row>
  </v-footer>
  <v-footer
    v-else-if="StateAuthenticated"
    id="home-footer"
    color="black"
    dark
    min-height="65"
    bottom
    fixed
  >
    <v-container>
      <v-row>
        <v-col cols="12" md="6">
          <div
            class="
              d-flex
              flex-wrap
              justify-md-start justify-center justify-md-none
            "
          >
            <template v-for="s in social">
              <template v-if="s === 'Facebook'">
                <a
                  :key="s"
                  class="white--text pa-1 pa-md-0"
                  href="https://www.facebook.com/FirstVault-108596295226047/"
                  target="_blank"
                >
                  <v-icon>mdi-facebook</v-icon> &nbsp;&nbsp;</a
                >
              </template>
              <template v-else-if="s === 'LinkedIn'">
                <a
                  :key="s"
                  class="white--text pa-1 pa-md-0"
                  href="https://in.linkedin.com/company/hedge-vision-systems-private-limited"
                  target="_blank"
                  ><v-icon>mdi-linkedin</v-icon> &nbsp;&nbsp;
                </a>
              </template>
            </template>
            <div class="pointerCursor" @click="showPrivacyPolicy">
              <span>&nbsp;Privacy Policy&nbsp;</span>
            </div>
            <div class="pointerCursor" @click="showTermsOfUse">
              <span>&nbsp;Terms of Use&nbsp;</span>
            </div>
          </div>
        </v-col>

        <v-col class="text-center text-md-right" cols="12" md="6">
          Copyright&copy; 2020-{{ getYear }} Hedge Vision Systems Pvt. Ltd.
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
  <v-footer v-else id="home-footer" color="black" dark min-height="65" bottom>
    <v-container>
      <v-row>
        <v-col cols="12" md="6">
          <div
            class="
              d-flex
              flex-wrap
              justify-md-start justify-center justify-md-none
            "
          >
            <template v-for="s in social">
              <template v-if="s === 'Facebook'">
                <a
                  :key="s"
                  class="white--text pa-1 pa-md-0"
                  href="https://www.facebook.com/FirstVault-108596295226047/"
                  target="_blank"
                >
                  <v-icon>mdi-facebook</v-icon> &nbsp;&nbsp;</a
                >
              </template>
              <template v-else-if="s === 'LinkedIn'">
                <a
                  :key="s"
                  class="white--text pa-1 pa-md-0"
                  href="https://in.linkedin.com/company/hedge-vision-systems-private-limited"
                  target="_blank"
                  ><v-icon>mdi-linkedin</v-icon> &nbsp;&nbsp;</a
                >
              </template>
            </template>
            <div class="pointerCursor" @click="showPrivacyPolicy">
              <span>&nbsp;Privacy Policy&nbsp;</span>
            </div>
            <div class="pointerCursor" @click="showTermsOfUse">
              <span>&nbsp;Terms of Use&nbsp;</span>
            </div>
          </div>
        </v-col>

        <v-col class="text-center text-md-right" cols="12" md="6">
          Copyright&copy; 2020-{{ getYear }} Hedge Vision Systems Pvt. Ltd.
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "HomeFooter",

  data: () => ({
    social: [
      "Facebook",
      //"Twitter",
      // 'Instagram',
      "LinkedIn",
    ],
  }),
  computed: {
    ...mapGetters(["StateAuthenticated"]),
    getYear() {
      var currentTime = new Date().getFullYear();
      return currentTime;
    },
  },
  methods: {
    showPrivacyPolicy() {
      this.$router.push("/privacypolicy");
    },
    showTermsOfUse() {
      this.$router.push("/termsofuse");
    },
  },
};
</script>

<style lang="sass">
#home-footer a
  text-decoration: none

.smallFont
  font-size: x-small

.link
  color: #FFFFFF

.pointerCursor
  cursor: pointer
</style>
